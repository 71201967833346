import "../styles/CaroselloStandard.css"

import React from "react";

interface CaroselloStandardProps {
    foto1: string
    foto2: string
    foto3: string
}

function CaroselloStandard ({foto1, foto2, foto3} : CaroselloStandardProps) {
    return(
        <div id="carouselExampleCaptions" className="carousel my-carousel slide m-auto" data-bs-ride="false" style={{margin: "1em", borderRadius: "1em"}}>
            <div className="carousel-indicators my-carousel">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>
            <div className="carousel-inner my-carousel" style={{borderRadius: "1em"}}>
                <div className="carousel-item my-carousel active">
                    <img src={foto1} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-none d-md-block">
                        <h5></h5>
                        <p></p>
                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={foto2} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-none d-md-block">
                        <h5></h5>
                        <p></p>
                    </div>
                </div>
                <div className="carousel-item my-carousel">
                    <img src={foto3} className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-none d-md-block">
                        <h5></h5>
                        <p></p>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev my-carousel" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next my-carousel" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    )
}

export default CaroselloStandard