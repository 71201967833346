import React from "react";
import "../../styles/CardNuoto.css"
import {useNavigate} from "react-router-dom";

interface CardNuotoProps {
    image: string;
    title: string;
    indirizzo: string;
}

function CardNuoto({ image, title, indirizzo }: CardNuotoProps) {
    const navigate = useNavigate()
    return (
        <div style={{ position: "relative", margin: "5px", textAlign: "center" }} onClick={()=>navigate(`${indirizzo}`)}>
            <img
                src={image}
                className="img-thumbnail"
                alt="..."
                style={{ width: "100%", maxWidth: "300px", height: "300px", objectFit: "cover" }}
            />
            <div
                style={{
                    position: "absolute",
                    top: "85%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "#fff",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    width: "100%",
                    textAlign: "center",
                    cursor: "pointer"
                }}
            >
                {title}
            </div>
        </div>
    );
}

export default CardNuoto;
