import React, {useState} from "react";

function ProvaAccordionSection() {
    const sections = [
        {
            id: "collapseULPS",
            label: "ULPS",
            content: "Informazioni sulla sezione ULPS.",
        },
        {
            id: "collapsePLUS",
            label: "PLUS",
            content: "Informazioni sulla sezione PLUS.",
        },
        {
            id: "collapseTRIS",
            label: "TRIS",
            content: "Informazioni sulla sezione TRIS.",
        },
        {
            id: "collapsePOKER",
            label: "POKER",
            content: "Informazioni sulla sezione POKER.",
        },
        {
            id: "collapseSUPER",
            label: "SUPER",
            content: "Informazioni sulla sezione SUPER.",
        },
        {
            id: "collapseOPEN",
            label: "OPEN",
            content: "Informazioni sulla sezione OPEN.",
        },
        {
            id: "collapseINGRESSI",
            label: "INGRESSI PISCINA E SAUNA",
            content: "Informazioni sulla sezione INGRESSI PISCINA E SAUNA.",
        },
    ];
    const [activeIndex, setActiveIndex] = useState(-1);

    const handleButtonClick = (index:any) => {
        // Chiudi tutte le sezioni se sono già aperte
        setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
    };

    return (
        <div className="accordion m-auto" id="accordionExample" style={{ width: "90%" }}>
            {sections.map((section, index) => (
                <div key={section.id} className="accordion-item">
                    <h2 className="accordion-header" id={`heading${index + 1}`}>
                        <button
                            className={`accordion-button ${index !== activeIndex ? "collapsed" : ""}`}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${section.id}`}
                            aria-expanded={index === activeIndex ? "true" : "false"}
                            aria-controls={section.id}
                            onClick={() => handleButtonClick(index)}
                        >
                            {section.label}
                        </button>
                    </h2>
                    <div
                        id={section.id}
                        className={`accordion-collapse collapse ${index === activeIndex ? "show" : ""}`}
                        aria-labelledby={`heading${index + 1}`}
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">{section.content}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProvaAccordionSection;
