import React from "react";
import CaroselloHome from "../components/home/CaroselloHome";

interface HomeProps {

}


function Home(_:HomeProps) {
    return(
        <div>
            <CaroselloHome />
        </div>
    )
}

export default Home