import React, { useState, useEffect } from "react";
import "../MultitessereSezioni.css";

function MultitessereSezioni() {
    const sections = [
        {
            id: "collapseULPS",
            label: "ULPS",
            content: "Informazioni sulla sezione ULPS.",
        },
        {
            id: "collapsePLUS",
            label: "PLUS",
            content: "Informazioni sulla sezione PLUS.",
        },
        {
            id: "collapseTRIS",
            label: "TRIS",
            content: "Informazioni sulla sezione TRIS.",
        },
        {
            id: "collapsePOKER",
            label: "POKER",
            content: "Informazioni sulla sezione POKER.",
        },
        {
            id: "collapseSUPER",
            label: "SUPER",
            content: "Informazioni sulla sezione SUPER.",
        },
        {
            id: "collapseOPEN",
            label: "OPEN",
            content: "Informazioni sulla sezione OPEN.",
        },
        {
            id: "collapseINGRESSI",
            label: "INGRESSI PISCINA E SAUNA",
            content: "Informazioni sulla sezione INGRESSI PISCINA E SAUNA.",
        },
    ];
    const [activePanel, setActivePanel] = useState<string | null>(
        sections.length > 0 ? sections[0].id : null
    );

    const handleButtonClick = (panelId: string) => {
        // Chiudi il pannello attivo se presente
        if (activePanel === panelId) {
            setActivePanel(null);
        } else {
            // Altrimenti, apri il nuovo pannello cliccato
            setActivePanel(panelId);
        }
    };



    return (
        <div className="container-fluid my-container d-flex flex-column m-auto ">
            <div
                className="d-flex flex-wrap p-flex"
                style={{
                    position: "relative",
                    zIndex: 1,
                    marginBottom: 0,
                    marginLeft: "8px",
                }}
            >
                {sections.map((section) => (
                    <button
                        key={section.id}
                        className={`btn btn-outline-secondary my-btn ${activePanel === section.id ? "active" : ""}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${section.id}`}
                        aria-expanded={activePanel === section.id ? "true" : "false"}
                        aria-controls={section.id}
                        onClick={() => handleButtonClick(section.id)}
                    >
                        {section.label}
                    </button>
                ))}

            </div>
            <div>
                {sections.map((section) => (
                    <div
                        key={section.id}
                        className={`collapse ${activePanel === section.id ? "show" : ""}`}
                        id={section.id}
                    >
                        <div className="card card-body my-card">{section.content}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default MultitessereSezioni;
