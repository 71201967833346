import React from "react";
import "../../styles/Tabella.css"

function TabellaOrariAvv() {
    return(
        <div className="table-container">
            <table className="table table-bordered m-auto" style={{width: "90%"}}>
                <thead>
                <tr>
                    <th className="header-cell" scope="col">Lun</th>
                    <th className="header-cell" scope="col">Mar</th>
                    <th className="header-cell" scope="col">Mer</th>
                    <th className="header-cell" scope="col">Gio</th>
                    <th className="header-cell" scope="col">Ven</th>
                    <th className="header-cell" scope="col">Sab</th>
                    <th className="header-cell" scope="col">Dom</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className="data-cell data-lg">17:00 - 17:45</td>
                    <td className="data-cell data-mv">17:00 - 17:45</td>
                    <td className="data-cell">17:00 - 17:45</td>
                    <td className="data-cell data-lg">17:00 - 17:45</td>
                    <td className="data-cell data-mv">17:00 - 17:45</td>
                    <td className="data-cell">11:15 - 11:55</td>
                    <td className="data-cell">11:50 - 12:30</td>
                </tr>
                <tr>
                    <td className="data-cell data-lg">17:45 - 18.30</td>
                    <td className="data-cell data-mv">17:45 - 18.30</td>
                    <td className="data-cell">17:45 - 18.30</td>
                    <td className="data-cell data-lg">17:45 - 18.30</td>
                    <td className="data-cell data-mv">17:45 - 18.30</td>
                    <td className="data-cell">12:00 - 12:40</td>
                    <td className="data-cell"></td>
                </tr>
                <tr>
                    <td className="data-cell data-lg">18:30 - 19.15</td>
                    <td className="data-cell data-mv">18:30 - 19.15</td>
                    <td className="data-cell">18:30 - 19.15</td>
                    <td className="data-cell data-lg">18:30 - 19.15</td>
                    <td className="data-cell data-mv">18:30 - 19.15</td>
                    <td className="data-cell"></td>
                    <td className="data-cell"></td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TabellaOrariAvv