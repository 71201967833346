import React, { useState, useEffect } from "react";
import ProvaAccordionSection from "../components/multitessere/ProvaAccordionSection";
import DueMultitessereSezioni from "../components/multitessere/DueMultitessereSezioni";

function Multitessere() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div>
            <div className="title d-flex flex-row m-4">
                <p className="display-4 fw-bold m-auto" style={{ color: "#41B6F4" }}>
                    MULTITESSERE
                </p>
            </div>
            <hr />
            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>Inserire testo...</p>
                </blockquote>
            </figure>
            <hr />

            {windowWidth > 900 ? (
                <DueMultitessereSezioni />
            ) : (
                <ProvaAccordionSection />
            )}

            <hr />
        </div>
    );
}

export default Multitessere;
