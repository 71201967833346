import React from "react";
import CardNuoto from "../../components/nuoto/CardNuoto";
import nuotoLibero from "../../images/Nuoto/nuoto_libero.jpg"
import corsi from "../../images/Nuoto/scuola_nuoto.jpeg"
import individuali from "../../images/Nuoto/individuali.jpeg"
import acquaGym from "../../images/Nuoto/acqua_gym.jpeg"
import riabilitazione from "../../images/Nuoto/riabilitazione.jpeg"
import "../../styles/CardNuoto.css"
import CaroselloStandard from "../../commons/CaroselloStandard";
import foto1 from "../../images/corsiNuoto/piscina_alto.jpeg"


function Nuoto() {
    return(
        <div>
            <div className="title d-flex flex-row m-4">
                <p className="display-4 fw-bold m-auto" style={{color: "#41B6F4"}}>ATTIVITÀ IN ACQUA</p>
            </div>
            <hr/>
            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>Inserire testo...</p>
                </blockquote>
            </figure>
            <hr/>
            <div className="card-container">
                <CardNuoto image={nuotoLibero} title={"Nuoto libero"} indirizzo={"/nuotolibero"}/>
                <CardNuoto image={corsi} title={"Corsi di nuoto"} indirizzo={"/corsinuoto"}/>
                <CardNuoto image={individuali} title={"Lezioni individuali"} indirizzo={"/lezionindividuali"} />
                <CardNuoto image={acquaGym} title={"Acquafitness"} indirizzo={"/acquafitness"}/>
                <CardNuoto image={riabilitazione} title={"Riabilitazione motoria"} indirizzo={"/riabilitazionemotoria"}/>
            </div>
        </div>
    )
}

export default Nuoto