import React from "react";
import CaroselloStandard from "../../commons/CaroselloStandard";
import foto1 from "../../images/Nuoto/individuali.jpeg"


function LezioniIndividuali() {
    return(
        <div>
            <div className="title d-flex flex-row m-4">
                <p className="display-4 fw-bold m-auto" style={{color: "#41B6F4"}}>LEZIONI INDIVIDUALI</p>
            </div>
            <CaroselloStandard foto1={foto1} foto2={foto1} foto3={foto1} />
            <hr/>
            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>Inserire testo...</p>
                </blockquote>
            </figure>
            <hr/>

            <hr/>
        </div>
    )
}


export default LezioniIndividuali
