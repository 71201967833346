import React from "react";
import CaroselloStandard from "../commons/CaroselloStandard";
import foto1 from "../images/Danza/danza1.jpeg"
import foto2 from "../images/Danza/danza2.jpeg"
import foto3 from "../images/Danza/danza3.jpeg"


function Danza() {
    return(
        <div>
            <div className="title d-flex flex-row m-4">
                <p className="display-4 fw-bold m-auto" style={{color: "#41B6F4"}}>DANZA</p>
            </div>
            <CaroselloStandard foto1={foto1} foto2={foto2} foto3={foto3} />
            <hr/>
            <figure className="text-center">
                <blockquote className="blockquote">
                    <p>Inserire testo...</p>
                </blockquote>
            </figure>
            <hr/>

            <hr/>
        </div>
    )
}


export default Danza
