import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";
import 'bootstrap/dist/css/bootstrap.min.css';


interface BarProps { }

function Bar(_: BarProps) {
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleNavClick = () => {
        setIsNavOpen(!isNavOpen); // Inverti lo stato quando viene cliccato il menu
    }

    const closeNav = () => {
        setIsNavOpen(false); // Chiudi il menu quando viene selezionata un'opzione
    }

    return (
        <nav className="navbar navbar-expand-lg" style={{ backgroundColor: "#42b6f5", height: "5em", zIndex: 1000  }}>
            <div className="container-fluid" style={{ alignContent: "end" }}>
                <NavLink className="navbar-brand text-white" to="/" style={{ fontFamily: "Impact, fantasy", marginLeft: "1em" }}>
                    <img className="nav-item-logo" src={logo} style={{ width: "2.5em" }} alt={"IL CIGNO"} />
                    IL CIGNO
                </NavLink>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={isNavOpen ? "true" : "false"}
                    aria-label="Toggle navigation"
                    onClick={handleNavClick}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>



                <div className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`} id="navbarNav">
                    <ul className="navbar-nav ms-auto" style={{}}>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Il Centro
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                    <NavLink className="dropdown-item" to="/chisiamo" onClick={closeNav}>
                                        Chi Siamo
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/contatti" onClick={closeNav}>
                                        Contatti
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/calendario" onClick={closeNav}>
                                        Calendario
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/regolamento" onClick={closeNav}>
                                        Regolamento
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/osteopatia" onClick={closeNav}>
                                        Osteopatia
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/visitemediche" onClick={closeNav}>
                                        Visite mediche
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/abbigliamento" onClick={closeNav}>
                                        Negozio abbigliamento
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/nuoto" onClick={closeNav}>
                                Nuoto
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/palestra" onClick={closeNav}>
                                Palestra
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/fitness" onClick={closeNav}>
                                Fitness
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/danza" onClick={closeNav}>
                                Danza
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/spa" onClick={closeNav}>
                                SPA
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/runnering" onClick={closeNav}>
                                Runnering
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/multitessere" onClick={closeNav}>
                                Multitessere
                            </NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Altri servizi
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark">
                                <li>
                                    <NavLink className="dropdown-item" to="/osteopata" onClick={closeNav}>
                                        Osteopata
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/visitemediche" onClick={closeNav}>
                                        Visite mediche
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/shop" onClick={closeNav}>
                                        Shop
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink className="dropdown-item" to="/ilcignocafé" onClick={closeNav}>
                                        Il Cigno café
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/news" onClick={closeNav}>
                                News
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link active text-white" to="/orari" onClick={closeNav}>
                                Orari
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Bar;
